import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
// import About from "./pages/About";
import Servicos from "./pages/Servicos";
import Contactos from "./pages/Contactos";
import Produtos from "./pages/Produtos";
import LocaisVenda from "./pages/LocaisVenda";
import Navbar from "./components/Navbar/Navbar";
import MobileMenu from "./components/Navbar/MobileMenu";
import Footer from "./components/Footer/Footer";

export default class App extends Component {
  render() {
    return (
      <Router>
        <Navbar />
        <MobileMenu />
        <Switch>
          <Route exact path="/">
            <div className="wrapper">
              <Home />
            </div>
          </Route>
          {/* <Route exact path="/sobre">
            <div className="wrapper">
              <About />
            </div>
          </Route> */}
          <Route exact path="/servicos">
            <div className="wrapper">
              <Servicos />
            </div>
          </Route>
          <Route exact path="/produtos">
            <div className="wrapper">
              <Produtos />
            </div>
          </Route>
          <Route exact path="/locais">
            <div className="wrapper">
              <LocaisVenda />
            </div>
          </Route>
          <Route exact path="/contactos">
            <div className="wrapper">
              <Contactos />
            </div>
          </Route>
        </Switch>
        <Footer />
      </Router>
    );
  }
}
