import React, { Component } from "react";
import "../styles/produtos.scss";

export default class Produtos extends Component {
  render() {
    return (
      <div className="produtos mdl-grid">
        <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--10-col-desktop mdl-cell--1-offset-desktop">
          <p className="title">produtos</p>
        </div>

        <div className="produtos_list mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--10-col-desktop mdl-cell--1-offset-desktop">
          <div style={{ marginBottom: "25px" }} className="mdl-grid">
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--12-col-desktop">
              <p className="produtct_title">Gás em garrafa</p>
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--6-col-tablet mdl-cell--1-offset-tablet mdl-cell--8-col-desktop mdl-cell--2-offset-desktop">
              <img alt="" src={require("../images/garrafa.png")} />
            </div>
          </div>

          <div style={{ marginBottom: "25px" }} className="mdl-grid">
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--12-col-desktop">
              <p className="produtct_title">Aquecedores de interior</p>
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--3-col-desktop mdl-cell--3-offset-desktop">
              <img alt="" src={require("../images/aquecedor1.png")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--3-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aquecedor2.png")} />
            </div>
          </div>

          <div style={{ marginBottom: "25px" }} className="mdl-grid">
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--12-col-desktop">
              <p className="produtct_title">Aquecedores de exterior</p>
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--3-col-desktop mdl-cell--3-offset-desktop">
              <img alt="" src={require("../images/aquecedor3.png")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--3-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aquecedor4.png")} />
            </div>
          </div>

          <div style={{ marginBottom: "25px" }} className="mdl-grid">
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--12-col-desktop">
              <p className="produtct_title">Barbecues</p>
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--3-col-desktop mdl-cell--3-offset-desktop">
              <img alt="" src={require("../images/barbecue1.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--3-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/barbecue2.jpg")} />
            </div>
          </div>

          <div style={{ marginBottom: "25px" }} className="mdl-grid">
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--12-col-desktop">
              <p className="produtct_title">Trempes de interior e exterior</p>
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--3-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/tt1.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--3-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/tt2.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--3-col-desktop mdl-cell--1-offset-desktop">
              <img
                alt=""
                style={{ paddingTop: "50px" }}
                src={require("../images/tt3.jpg")}
              />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--3-col-desktop mdl-cell--3-offset-desktop">
              <img
                alt=""
                style={{ paddingTop: "50px" }}
                src={require("../images/tt4.jpg")}
              />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--3-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/tt5.jpg")} />
            </div>
          </div>

          <div style={{ marginBottom: "25px" }} className="mdl-grid">
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--12-col-desktop">
              <p className="produtct_title">Esquentadores Junkers / Bosh</p>
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--2-offset-desktop">
              <img alt="" src={require("../images/es1.png")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/es2.png")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/es3.png")} />
            </div>
          </div>

          <div style={{ marginBottom: "25px" }} className="mdl-grid">
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--12-col-desktop">
              <p className="produtct_title">
                Painéis solares térmicos – Termosifão
              </p>
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--3-col-desktop mdl-cell--3-offset-desktop">
              <img alt="" src={require("../images/ps1.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--3-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/ps2.jpg")} />
            </div>
          </div>

          <div style={{ marginBottom: "25px" }} className="mdl-grid">
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--12-col-desktop">
              <p className="produtct_title">Ekoology – Biocarvão</p>
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--3-col-desktop mdl-cell--3-offset-desktop">
              <img alt="" src={require("../images/ekoology.png")} />
            </div>
          </div>

          <div style={{ marginBottom: "25px" }} className="mdl-grid">
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--12-col-desktop">
              <p className="produtct_title">Representante Campingaz</p>
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--2-offset-desktop">
              <img alt="" src={require("../images/cg2.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/cg7.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/cg1.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--2-offset-desktop">
              <img alt="" src={require("../images/cg3.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/cg4.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/cg5.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--2-offset-desktop">
              <img alt="" src={require("../images/cg6.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/cg8.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/cg9.jpeg")} />
            </div>
          </div>

          <div style={{ marginBottom: "25px" }} className="mdl-grid">
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--12-col-desktop">
              <p className="produtct_title">
                Vasta gama de acessórios para instalações de gás
              </p>
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aa1.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aa2.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aa3.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aa5.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aa6.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aa7.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aa8.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aa9.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aa10.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aa11.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aa12.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--2-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aa13.jpg")} />
            </div>

            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--4-col-desktop mdl-cell--2-offset-desktop">
              <img alt="" src={require("../images/aa15.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--4-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aa16.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--4-col-desktop mdl-cell--2-offset-desktop">
              <img alt="" src={require("../images/aa17.jpg")} />
            </div>
            <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--4-col-tablet mdl-cell--4-col-desktop mdl-cell--1-offset-desktop">
              <img alt="" src={require("../images/aa18.jpg")} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
