import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import $ from "jquery";

export default class TopNavbarLink extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    if (this.props.mobile) {
      $(".mobilemenu").slideToggle();
      if ($("body").hasClass("mobileActive")) {
        $("body").removeClass("mobileActive");
      } else {
        $("body").addClass("mobileActive");
      }
    }
  }
  render() {
    return (
      <li>
        <NavLink exact to={this.props.url} onClick={this.handleClick}>
          {this.props.name}
        </NavLink>
      </li>
    );
  }
}
