import React, { Component } from "react";
import "../styles/contactos.scss";

export default class Contactos extends Component {
  render() {
    return (
      <div className="contactos row">
        <div className="text col-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
          <p className="title">Contactos</p>
        </div>
        <div className="row">
          <div className="item col-12 col-sm-5 offset-sm-1 col-md-3 offset-md-2 col-lg-4 offset-lg-2">
            <p className="subtitle">Sede / Loja 1 Silves Gare</p>
            <p class="m-0">
              Telf.: +351 282 443 782 <i>(Chamada para a rede fixa nacional)</i>
            </p>
            <p className="subtitle mt-3">Loja 2 Albufeira</p>
            <p>
              Telf.: +351 289 572 615 <i>(Chamada para a rede fixa nacional)</i>
            </p>
          </div>
          <div className="item col-12 col-sm-5 offset-sm-1 col-md-3 offset-md-2 col-lg-4 offset-lg-2">
            <img
              alt=""
              style={{ width: "100%" }}
              src={require("../images/loja1.jpg")}
            />
          </div>
          <div className="item col-12 col-sm-5 offset-sm-1 col-md-3 offset-md-2 col-lg-4 offset-lg-2">
            <p className="subtitle">Pedidos de Gás</p>
            <p className="subtitle2">Silves</p>
            <p>
              +351 966 958 368 <i>(Chamada para rede móvel nacional)</i>
            </p>
            <p className="subtitle2">Albufeira</p>
            <p>
              +351 969 455 781 <i>(Chamada para rede móvel nacional)</i>
            </p>
          </div>
          <div className="item col-12 col-sm-5 offset-sm-1 col-md-3 offset-md-2 col-lg-4 offset-lg-2">
            <img
              alt=""
              style={{ width: "100%" }}
              src={require("../images/loja2b.jpg")}
            />
          </div>
          <div className="item col-12 col-sm-5 offset-sm-1 col-md-3 offset-md-2 col-lg-4 offset-lg-2">
            <img
              alt=""
              style={{ width: "50px" }}
              className="image_icon"
              src={require("../images/assistencia.jpg")}
            />
            <p className="subtitle">Assistência técnica</p>
            <p style={{ paddingLeft: "50px" }}>
              +351 963 568 516 <i>(Chamada para rede móvel nacional)</i>
            </p>
          </div>
          <div className="item col-12 col-sm-5 offset-sm-1 col-md-3 offset-md-2 col-lg-4 offset-lg-2">
            <img
              alt=""
              style={{ width: "40px" }}
              className="image_icon"
              src={require("../images/email.jpg")}
            />
            <p className="subtitle">Email</p>
            <p style={{ paddingLeft: "40px" }}>
              <a href="mailto:sequeiraeduarte@sapo.pt">
                sequeiraeduarte@sapo.pt
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}
