import React, { Component } from "react";

export default class Footer extends Component {
  render() {
    return (
      <div className="row footer">
        <div className="col-6 col-sm-6 col-md-6 col-lg-6 center">
          <p>
            <span>
              © 2016 Sequeira & Duarte LDA. Todos os direitos reservados
            </span>
          </p>
          <p>
            <a
            className="footer_link"
              target="_blank"
              href="/Sequeira-Duarte-Informacao-Consumidor.pdf"
            >
              Informação ao Consumidor
            </a>
          </p>
          <p>
            <a
            className="footer_link"
              target="_blank"
              href="/PPrivacidade.pdf"
            >
              Política de Privacidade
            </a>
          </p>
        </div>
        <div className="col-6 col-sm-6 col-md-6 col-lg-6 right footer_icons">
          <a
            className="footer_icon"
            target="_blank"
            href="https://www.facebook.com/sequeiraeduartelda"
          >
            <i className="fa fa-facebook-square"></i>
          </a>
        </div>
      </div>
    );
  }
}
