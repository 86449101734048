export default {
  algoz: [
    {
      name: "VITALINO NEVES – Raçoes",
      address: "Rua do Alto do Palmeiral",
    },
  ],
  tunes: [
    {
      name: "SUPERMERCADOS COVIRAN",
      address: "RUA 25 ABRIL, LOJA A",
    },
    {
      name: "Snack Bar Piteu",
      address: "Estrada N269 - Assomadas",
    },
  ],
  pera: [
    {
      name: "Jose F. M. do Carmo",
      address: "Rua Alexandre Herculano Nº46",
    },
  ],
  serra: [
    {
      name: "Drogaria 'A Nova'",
      address: "Rua Largo da Fabrica Velha, Nº1",
    },
    {
      name: "Auzenda Vieira Unipessoal, Lda",
      address: "Rua da Igreja Nº16",
    },
    {
      name: "Cestinho de Compras",
      address: "Rua do Comercio Nº14",
    },
    {
      name: "Andre Miguel Guerreiro Coelho",
      address: "Azilheira",
    },
  ],
  alcantarilha: [
    {
      name: "Agrimal Rações",
      address: "Lugar Bacharel, Estr. Fontes Matosa",
    },
    {
      name: "Leal Reparadora",
      address: "Rua das Palmeiras",
    },
  ],
  silves: [
    {
      name: "Café Retiro dos Pescadores",
      address: "Sitio da Pedreira",
    },
    {
      name: "RG Raçoes Lda",
      address: "Ladeira de S. Pedro",
    },
    {
      name: "Pastelaria Canivete",
      address: "Rua Dr. Jose Julio Martins, Enxerim",
    },
    {
      name: "Ageas Seguros",
      address: "Bairro Joao Jose Duarte, Rua do Castelo",
    },
    {
      name: "Mini Mercado Terruta",
      address: "Rua cruz de Portugal Nº39",
    },
  ],
  messines: [
    {
      name: "Mini Mercado Fatima",
      address: "Rua Antonio Aleixo nº17",
    },
    {
      name: "Café o Jorge",
      address: "Rua Joao De Deus nº 121",
    },
    {
      name: "Supermercado - Grilo Henriques Lda",
      address: "Rua da Liberdade",
    },
    {
      name: "VITALINO NEVES - Supermercado Coviram",
      address: "Rua principal da amorosa",
    },
    {
      name: "Mini Mercado Naicinha",
      address: "Rua Luis de Camoes",
    },
    {
      name: "Tasca Beira Serra",
      address: "Fonte Joao Luis",
    },
    {
      name: "Casa Das Rações",
      address: "Aldeia Ruiva",
    },
    {
      name: "Cantinho da Adelaide",
      address: "Rua da Junqueira nº5",
    },
    {
      name: "Snack-Bar Restaurante Estrela do Sul",
      address: "Ribeiro Martinho",
    },
    {
      name: "Eurico Martins Mealha",
      address: "Rua T. C. Jorge V. Mogo",
    },
    {
      name: "Messitalhos Lda",
      address: "Rua da Liberdade Nº30",
    },
    {
      name: "O Cantinho D Ana VI - Amanhecer",
      address: "Rua  D Maria Eugenia Ferreira",
    },
    {
      name: "Guia & Pires Lda",
      address: "Assumadas",
    },
    {
      name: "Posto BP Messines Norte",
      address: "IC1 - Tunes - Messines",
    },
    {
      name: "Posto BP Messines Sul",
      address: "IC1 - Messines - Tunes",
    },
  ],
  albufeira: [
    { name: "Drogaria Pedro Martins", address: "Rua Jornal A Avezinha" },
    { name: "Rua Alves Redol", address: "Rua Alves Redol" },
    { name: "Graça", address: "Praceta Das Flores" },
    { name: "Minimercado Bispo", address: "Rua Sir Cliff Richard" },
    { name: "Celeiro da Branqueira", address: "Estrada M526 (Branqueira)" },
    { name: "Posto Combustivel BP", address: "Avenida dos Descobrimentos" },
    { name: "Restaurante Martins", address: "Estrada dos Brejos" },
    {
      name: "Papelaria Numeros Animados",
      address: "Beco das Perdizes (McDonald)",
    },
    { name: "Oceano Supermercados", address: "Estrada M526 (Patroves)" },
    { name: "Intermarché Albufeira", address: "Cerro Grande" },
    { name: "Supermercado Castelo (Spar)", address: "Estrada do Castelo" },
  ],
  olhosagua: [
    { name: "Minimercado Vieira", address: "Estrado do Toy 70" },
    { name: "Café Hamburgo", address: "Estrada do Toy 70" },
    { name: "Snack Bar O Beiral", address: "Estrada das Açoteias M1289" },
    { name: "O Berto", address: "Estrada das Açoteias M1289" },
    { name: "Supermercado Mira Parque", address: "Estrada das Açoteias M1289" },
  ],
  guia: [{ name: "Bar O Cantinho", address: "Estrada M1281 (Monte Juntos)" }],
  ferreiras: [
    { name: "Drogaria Lacosalagarve", address: "Tominhal" },
    { name: "Mini Mercado Otilia Vieira", address: "Estrada dos Cerros Altos" },
    { name: "Mercearia Fontainhas", address: "Rua das Fontainhas" },
  ],
};
