import React, { Component } from "react";
import "../styles/home.scss";

export default class Home extends Component {
  render() {
    return (
      <div className="entrada">
        <div className="capa row">
          <div className="text col-10 offset-1">
            <p className="title">sequeira & duarte lda</p>
            <p className="desc">
              Se depender da sua mãe nunca lhe vai faltar calor.
            </p>
            <p className="desc2">Se depender de nós, também não.</p>
            <p className="desc">A aquecer Silves e Albufeira.</p>
          </div>
        </div>
        <div className="intro row">
          <div className="col-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
            <p className="intro_text">
              <p>
                Fundada em 1989, a Sequeira & Duarte, Lda. é uma empresa
                credenciada no Ministério da Energia na D.G.E.G., como entidade
                instaladora de gás Tipo A+B. Que nos últimos anos tem vindo a
                ocupar um lugar de destaque no ramo do gás no concelho de Silves
                e agora mais recentemente também no concelho de Albufeira.
              </p>
              <p>
                Para isso temos ao vosso dispor entregas de gás porta a porta e
                vários postos de venda espalhados pelos dois concelhos, para um
                mais fácil acesso, complementado com uma equipa de assistência
                técnica 24 Horas. Em conjunto com uma gama de produtos de grande
                qualidade para instalações de gás, aparelhos de queima e até na
                área das energias renováveis.
              </p>
              <p>
                Uma equipa que conta com colaboradores credenciados, para melhor
                o servir e ajudar, maior seriedade, rigor, profissionalismo e
                simpatia.
              </p>
            </p>
          </div>
        </div>
        <div className="dicas_container row">
          <div className="col-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
            <span className="dicas_text">
              O gás é um bem essencial para o dia a dia de todos nós.
            </span>
          </div>
          <div
            style={{ marginTop: "10px" }}
            className="col-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-10 offset-lg-1"
          >
            <span className="dicas_text">
              O que varia é o uso que dele fazemos, para poupar em segurança.
            </span>
          </div>
        </div>
        <div className="row dicas_list">
          <div className="col-12 col-sm-5 offset-sm-1 col-md-5 offset-md-1 col-lg-5 offset-lg-1">
            <img
              alt=""
              src={require("../images/seguranca.png")}
              height="100px"
            />
            <ul>
              <li>
                Verifique a boa condição e a data de validade do tubo de
                borracha.
              </li>
              <li>
                Verifique o aperto das abraçadeiras junto ao redutor e ao
                equipamento de queima.
              </li>
              <li>
                Coloque a garrafa sempre na vertical e ligue corretamente o
                redutor.
              </li>
              <li>Verifique a correta exaustão dos aparelhos de queima.</li>
              <li>
                Verifique a correta localização das garrafas de gás (nunca
                utilizar nas caves ou junto a grelhas de esgoto).
              </li>
              <li>
                Verifique a boa condição dos materiais e a data de validade
                da(s) lira(s), na cabine de garrafas.
              </li>
              <li>
                Em caso de fuga feche imediatamente o gás (coloque o manípulo do
                redutor na posição fechado).
              </li>
              <li>Não faça lume e apague eventuais chamas.</li>
              <li>
                Areje o local, abrindo portas e janelas, de forma a provocar uma
                corrente de ar.
              </li>
              <li>Não ligue ou desligue interruptores eléctricos.</li>
            </ul>
          </div>
          <div className="col-xs-12 col-sm-5 col-md-5 col-lg-5">
            <img
              alt=""
              src={require("../images/poupanca.png")}
              height="100px"
            />
            <ul>
              <li>
                Quando cozinha, escolha os bicos de fogão mais pequenos (poupa
                até 10%), tape as panelas e reduza a intensidade da chama quando
                atingir a fervura.
              </li>
              <li>
                Desligue a boca do fogão alguns minutos antes do previsto. O
                calor acumulado terminará a confeção dos alimentos.
              </li>
              <li>
                Controle o tempo dos banhos de chuveiro e evite banhos de
                emersão.
              </li>
              <li>
                Desligue o esquentador ou a caldeira se não os vai utilizar
                durante longos períodos.
              </li>
              <li>
                Se tem por hábito deixar o aquecimento central ligado durante a
                noite ou mesmo durante o dia, opte por baixar a temperatura.
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}
