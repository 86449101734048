import React, { Component } from "react";
import { Link } from "react-router-dom";
import TopNavbarLink from "./TopNavbarLink";
import $ from "jquery";

export default class Navbar extends Component {
  openMobileMenu() {
    $(".mobilemenu").slideToggle();
    if ($("body").hasClass("mobileActive")) {
      $("body").removeClass("mobileActive");
    } else {
      $("body").addClass("mobileActive");
    }
  }
  render() {
    return (
      <div className="navbar">
        <div className="navbarbottom">
          <Link to={"/"}>
            <img
              alt=""
              className="logo"
              src={require("../../images/logo.png")}
            />
          </Link>
          <ul className="links">
            <TopNavbarLink url={"/"} name={"Início"} mobile={false} />
            {/* <TopNavbarLink url={"/sobre"} name={"A Empresa"} mobile={false} /> */}
            <TopNavbarLink url={"/servicos"} name={"Serviços"} mobile={false} />
            <TopNavbarLink url={"/produtos"} name={"Produtos"} mobile={false} />
            <TopNavbarLink
              url={"/locais"}
              name={"Locais de Venda"}
              mobile={false}
            />
            <TopNavbarLink
              url={"/contactos"}
              name={"Contactos"}
              mobile={false}
            />
          </ul>
          <div className="navbar-toggle" onClick={this.openMobileMenu}>
            <div className="icon-bar"></div>
            <div className="icon-bar"></div>
            <div className="icon-bar"></div>
          </div>
        </div>
      </div>
    );
  }
}
