import React, { Component } from "react";
import TopNavbarLink from "./TopNavbarLink";

export default class MobileMenu extends Component {
  render() {
    return (
      <div className="mobilemenu">
        <ul>
          <TopNavbarLink url={"/"} name={"Início"} mobile={true} />
          {/* <TopNavbarLink url={"/sobre"} name={"A Empresa"} mobile={true}/> */}
          <TopNavbarLink url={"/servicos"} name={"Serviços"} mobile={true} />
          <TopNavbarLink url={"/produtos"} name={"Produtos"} mobile={true} />
          <TopNavbarLink
            url={"/locais"}
            name={"Locais de Venda"}
            mobile={true}
          />
          <TopNavbarLink url={"/contactos"} name={"Contactos"} mobile={true} />
        </ul>
      </div>
    );
  }
}
