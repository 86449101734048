import React, { Component } from "react";
import locais from "../helpers/locaisvenda";
import $ from "jquery";
import "../styles/locais.scss";

export default class LocaisVenda extends Component {
  constructor(props) {
    super(props);
    this.openModal = this.openModal.bind(this);
  }

  openModal(zoneid) {
    $("#zone_dialog").modal();
    $("#modal_body").empty();
    $("#modal_body").append('<ul id="modal_list"></ul>');
    let title = "";

    if (zoneid === 1) {
      title = "S. Marcos da Serra";
      locais.serra.forEach((l) => {
        $("#modal_list").append(
          "<li><span>" +
            l.name +
            " - </span><span>" +
            l.address +
            "</span></li>"
        );
      });
    } else if (zoneid === 2) {
      title = "S. Bartolomeu de Messines";
      locais.messines.forEach((l) => {
        $("#modal_list").append(
          "<li><span>" +
            l.name +
            " - </span><span>" +
            l.address +
            "</span></li>"
        );
      });
    } else if (zoneid === 3) {
      title = "Silves";
      locais.silves.forEach((l) => {
        $("#modal_list").append(
          "<li><span>" +
            l.name +
            " - </span><span>" +
            l.address +
            "</span></li>"
        );
      });
    } else if (zoneid === 4) {
      title = "Algoz";
      locais.algoz.forEach((l) => {
        $("#modal_list").append(
          "<li><span>" +
            l.name +
            " - </span><span>" +
            l.address +
            "</span></li>"
        );
      });
    } else if (zoneid === 5) {
      title = "Alcantarilha";
      locais.alcantarilha.forEach((l) => {
        $("#modal_list").append(
          "<li><span>" +
            l.name +
            " - </span><span>" +
            l.address +
            "</span></li>"
        );
      });
    } else if (zoneid === 6) {
      title = "Tunes";
      locais.tunes.forEach((l) => {
        $("#modal_list").append(
          "<li><span>" +
            l.name +
            " - </span><span>" +
            l.address +
            "</span></li>"
        );
      });
    } else if (zoneid === 7) {
      title = "Pêra";
      locais.pera.forEach((l) => {
        $("#modal_list").append(
          "<li><span>" +
            l.name +
            " - </span><span>" +
            l.address +
            "</span></li>"
        );
      });
    } else if (zoneid === 8) {
      title = "Armação de Pêra";
      $("#modal_list").append("<li><span>Nada</span></li>");
    } else if (zoneid === 9) {
      title = "Paderne";
      $("#modal_list").append("<li><span>Nada</span></li>");
    } else if (zoneid === 10) {
      title = "Guia";
      locais.guia.forEach((l) => {
        $("#modal_list").append(
          "<li><span>" +
            l.name +
            " - </span><span>" +
            l.address +
            "</span></li>"
        );
      });
    } else if (zoneid === 11) {
      title = "Ferreiras";
      locais.ferreiras.forEach((l) => {
        $("#modal_list").append(
          "<li><span>" +
            l.name +
            " - </span><span>" +
            l.address +
            "</span></li>"
        );
      });
    } else if (zoneid === 12) {
      title = "Albufeira";
      locais.albufeira.forEach((l) => {
        $("#modal_list").append(
          "<li><span>" +
            l.name +
            " - </span><span>" +
            l.address +
            "</span></li>"
        );
      });
    } else if (zoneid === 13) {
      title = "Olhos de Agua";
      locais.olhosagua.forEach((l) => {
        $("#modal_list").append(
          "<li><span>" +
            l.name +
            " - </span><span>" +
            l.address +
            "</span></li>"
        );
      });
    }

    $("#modal_title").text(title);
  }

  render() {
    return (
      <div className="locais mdl-grid">
        <div className="mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--10-col-desktop mdl-cell--1-offset-desktop">
          <p className="title">Locais de venda</p>
        </div>
        <div
          style={{ position: "relative" }}
          className="map_container mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--8-col-desktop mdl-cell--3-offset-desktop"
        >
          <p className="subtitle">Silves</p>
          <img
            alt=""
            className="image_click"
            src={require("../images/concelho.png")}
          />
          <p
            onClick={() => this.openModal(1)}
            className="zone_item"
            id="zone_item1"
          ></p>
          <p
            onClick={() => this.openModal(2)}
            className="zone_item"
            id="zone_item2"
          ></p>
          <p
            onClick={() => this.openModal(3)}
            className="zone_item"
            id="zone_item3"
          ></p>
          <p
            onClick={() => this.openModal(4)}
            className="zone_item"
            id="zone_item4"
          ></p>
          <p
            onClick={() => this.openModal(5)}
            className="zone_item"
            id="zone_item5"
          ></p>
          <p
            onClick={() => this.openModal(6)}
            className="zone_item"
            id="zone_item6"
          ></p>
          <p
            onClick={() => this.openModal(7)}
            className="zone_item"
            id="zone_item7"
          ></p>
          <p
            onClick={() => this.openModal(8)}
            className="zone_item"
            id="zone_item8"
          ></p>
        </div>
        <div
          style={{ position: "relative" }}
          className="map_container mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--8-col-desktop mdl-cell--3-offset-desktop mt-5 pb-5"
        >
          <p className="subtitle">Albufeira</p>
          <img
            alt=""
            className="image_click"
            src={require("../images/concelho_albufeira.png")}
          />
          <p
            onClick={() => this.openModal(9)}
            className="zone_item"
            id="zone_item_al_1"
          ></p>
          <p
            onClick={() => this.openModal(11)}
            className="zone_item"
            id="zone_item_al_2"
          ></p>
          <p
            onClick={() => this.openModal(10)}
            className="zone_item"
            id="zone_item_al_3"
          ></p>
          <p
            onClick={() => this.openModal(13)}
            className="zone_item"
            id="zone_item_al_4"
          ></p>
          <p
            onClick={() => this.openModal(12)}
            className="zone_item"
            id="zone_item_al_5"
          ></p>
        </div>
        <div className="map_list mdl-cell mdl-cell--4-col-phone mdl-cell--8-col-tablet mdl-cell--12-col-desktop">
          <p className="subtitle mb-3">Silves</p>
          <ul>
            <li onClick={() => this.openModal(1)}>S. Marcos da Serra</li>
            <li onClick={() => this.openModal(2)}>S. Bartolomeu de Messines</li>
            <li onClick={() => this.openModal(3)}>Silves</li>
            <li onClick={() => this.openModal(4)}>Algoz</li>
            <li onClick={() => this.openModal(5)}>Alcantarilha</li>
            <li onClick={() => this.openModal(6)}>Tunes</li>
            <li onClick={() => this.openModal(7)}>Pêra</li>
            <li onClick={() => this.openModal(8)}>Armação de Pêra</li>
          </ul>
          <p className="subtitle mt-5 mb-3">Albufeira</p>
          <ul>
            <li onClick={() => this.openModal(9)}>Paderne</li>
            <li onClick={() => this.openModal(10)}>Guia</li>
            <li onClick={() => this.openModal(11)}>Ferreiras</li>
            <li onClick={() => this.openModal(12)}>Albufeira</li>
            <li onClick={() => this.openModal(13)}>Olhos de Agua</li>
          </ul>
        </div>
        <div
          id="zone_dialog"
          className="modal fade"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h4
                  className="modal-title"
                  id="modal_title"
                  style={{ margin: 0 }}
                >
                  Modal title
                </h4>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <div id="modal_body"></div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default btn-close"
                  data-dismiss="modal"
                >
                  Fechar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
