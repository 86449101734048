import React, { Component } from "react";
import "../styles/servicos.scss";

export default class Servicos extends Component {
  render() {
    return (
      <div className="servicos">
        <div className="row">
          <div className="text col-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
            <p className="title">serviços</p>
          </div>
        </div>
        <div className="row servicos_list">
          <div className="col-12 col-sm-5 offset-sm-1 col-md-5 offset-md-1 col-lg-5 offset-lg-1">
            <p className="servico_title">Gás em garrafa</p>
            <ul>
              <li>Serviço de entrega ao domicílio para maior comodidade.</li>
              <li>
                Serviço de entregas urgentes, aos fins-de-semana e feriados.
              </li>
              <li>Piquete de assistência técnica 24horas.</li>
            </ul>
          </div>
          <div className="col-12 col-sm-5 col-md-5 col-lg-5 col-xl-5">
            <p className="servico_title">Assistência técnica ao domicílio</p>
            <ul>
              <li>Instalação e assistência de redes de gás.</li>
              <li>Instalação e assistência de aparelhos de queima.</li>
              <li>
                Inspeção e substituição de liras e tubos de borracha fora do
                prazo validade.
              </li>
            </ul>
          </div>
        </div>
        <div className="row servico_seg_txt">
          <div className="col-12 col-sm-10 offset-sm-1 col-md-10 offset-md-1 col-lg-10 offset-lg-1">
            <p className="servico_title">
              A <span className="sqlda">Sequeira & Duarte Lda</span> dispõe
              ainda de um seguro casa e bens para todos os seus clientes
              totalmente gratuito e de Piquete de Segurança 24 horas.
            </p>
          </div>
        </div>
        <div className="row servico_seg_img align-items-center">
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 right">
            <img alt="" src={require("../images/seguro.png")} height="100px" />
          </div>
          <div className="col-12 col-sm-6 col-md-6 col-lg-6 left">
            <img alt="" src={require("../images/piquete.jpg")} height="200px" />
          </div>
        </div>
      </div>
    );
  }
}
